import React from "react";
import { compose } from "recompose";

import Layout from "../components/layout";
import RecordAdmin from "../components/Record/RecordAdmin";
import {
  withAuthorization,
  withEmailVerification,
} from "../components/Account/Session";
import { ROLES, OPERATIONS } from "../constants";

const condition = (authUser) =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const RecordAdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(RecordAdmin);

export default ({ location }) => (
  <Layout location={location}>
    <RecordAdminPage
      location={location}
      operation={OPERATIONS.CREATE}
    />
  </Layout>
);
