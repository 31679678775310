import styled from "styled-components";

export const StyledWhitePage = styled.div`
  background-color: white;
  margin: -1rem;
  padding: 1rem;
`;

export const StyledRecord = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
    overflow: hidden;
  }
  > div {
    font-size: 1.5rem;
    &:first-child {
      padding-right: 2rem;
    }
  }
  img {
    box-shadow: 7px 7px 15px #404040;
    width: 100%;
    max-width: 500px;
    display: block;
  }
  ul {
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      display: block;
    }
  }
  input,
  select,
  button {
    font-size: 1.5rem;
    width: 18rem;
    @media (max-width: 1024px) {
      margin-bottom: 0.5rem;
    }
  }
  input[type="file"] {
    width: auto;
  }
  select {
    width: calc(18rem + 6px);
    line-height: 2rem;
  }
  button {
    width: unset;
  }
  label {
    font-size: 1.5rem;
    display: inline-block;
    padding-right: 0.5rem;
    width: 14rem;
    margin-right: 0.5rem;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  span {
    font-weight: bold;
  }
  button {
    margin-right: 0.5rem;
    padding: 0.5rem;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .fieldset {
    display: inline-block;
    margin-bottom: 0.5rem;
    a {
      color: darkslategrey;
    }
  }
`;
