import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Helmet from "react-helmet";

import { CATEGORIES } from "../../constants";
import { getCategoryName, getCoverType } from "../../helpers";
import { Link } from "gatsby";
import { StyledWhitePage, StyledRecord } from "./styles";

const RecordView = ({ record, canEdit, editUrl }) => {
  const {
    artist,
    a_side,
    b_side,
    imgUrl,
    cover_id,
    cat_id,
    youtube_id1,
    youtube_id2,
    lastUpdated,
  } = record;
  return (
    <StyledWhitePage>
      <Helmet>
        <title>
          {artist} - {a_side} / {b_side} | Dirk's Jukebox
        </title>
      </Helmet>
      <button
        onClick={(e) => {
          e.preventDefault();
          history.back();
        }}
        style={{ float: "right" }}
        title="Terug"
        rel="prev"
      >
        ←
      </button>
      <h1>
        {artist} - {a_side} / {b_side}
      </h1>
      <StyledRecord>
        <div>
          <img
            src={
              imgUrl.length > 0
                ? imgUrl
                : `/img/cover_${cover_id}.png`
            }
            alt={`Cover ${a_side}`}
          />
        </div>
        <div>
          <ul>
            <li>
              <label>Artiest:</label>
              <span>{artist}</span>
            </li>
            <li>
              <label>A Kant:</label>
              <span>{a_side}</span>
            </li>
            <li>
              <label>B Kant:</label>
              <span>{b_side}</span>
            </li>
            <li>
              <label>Type hoes:</label>
              <span>{getCoverType(cover_id)}</span>
            </li>
            <li>
              <label>Categorie:</label>
              <span>{getCategoryName(cat_id)}</span>
            </li>
            <li>
              <label>YouTube A Kant:</label>
              {youtube_id1 ? (
                <div className="videoWrapper">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${youtube_id1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <span>-</span>
              )}
            </li>
            <li>
              <label>YouTube B Kant:</label>
              {youtube_id2 ? (
                <div className="videoWrapper">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${youtube_id2}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <span>-</span>
              )}
            </li>
            <li>
              <label>Laatst gewijzigd:</label>
              <span>
                {new Date(lastUpdated).toLocaleDateString()}
              </span>
            </li>
          </ul>
          {!!canEdit && cat_id !== CATEGORIES.DELETED.ID && (
            <Link to={editUrl}>
              <button>
                <span>✍️</span> Wijzigen
              </button>
            </Link>
          )}
        </div>
      </StyledRecord>
    </StyledWhitePage>
  );
};

RecordView.propTypes = {
  record: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  editUrl: PropTypes.string,
};

export default RecordView;
