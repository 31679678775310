import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { OPERATIONS, ROUTES, EMPTY_RECORD } from "../../constants";
import {
  fetchRecordFromLocalStorage,
  saveRecord,
} from "../../helpers";
import { withFirebase } from "../Firebase";
import EditForm from "./EditForm";
import RecordView from "./RecordView";

const RecordAdmin = (props) => {
  const { location, firebase, record, operation } = props;
  const { pathname, search, hash } = location;
  const [latestRecordId, setRecordSingleId] = useState(null);

  const recordUrl = `${pathname}${search}`;
  const editMode =
    hash === "#edit" || operation === OPERATIONS.CREATE;

  const editableRecord =
    operation === OPERATIONS.CREATE
      ? { ...EMPTY_RECORD, id: latestRecordId + 1 }
      : fetchRecordFromLocalStorage(record);

  useEffect(() => {
    if (firebase) {
      const latestRecordIdRef = firebase.meta("latestRecordId");
      latestRecordIdRef.on("value", (snapshot) => {
        setRecordSingleId(snapshot.val());
      });
    }
  });

  return (
    <Fragment>
      {editMode ? (
        <EditForm
          record={editableRecord}
          handleSave={async (updatedRecord, operation) => {
            await saveRecord(firebase, updatedRecord, operation);
            operation === OPERATIONS.UPDATE
              ? navigate(recordUrl)
              : navigate(ROUTES.ADMIN, {
                  state: {
                    message: `Toegevoegd: ${updatedRecord.artist}: ${updatedRecord.a_side} / ${updatedRecord.b_side}`,
                  },
                });
          }}
          handleDelete={async () => {
            const confirmed = await saveRecord(
              firebase,
              editableRecord,
              OPERATIONS.DELETE,
            );
            if (confirmed) {
              navigate(ROUTES.RECORDS, {
                state: {
                  message: `Verwijderd: ${editableRecord.artist}: ${editableRecord.a_side} / ${editableRecord.b_side}`,
                },
              });
            }
          }}
          getStorageRef={() => firebase.storageRef()}
          operation={operation || OPERATIONS.UPDATE}
        />
      ) : (
        <RecordView
          record={fetchRecordFromLocalStorage(record)}
          canEdit={true}
          editUrl={`${recordUrl}#edit`}
        />
      )}
    </Fragment>
  );
};

RecordAdmin.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOf[(PropTypes.number, PropTypes.string)],
  }),
};

export default withFirebase(RecordAdmin);
