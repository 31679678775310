import React, { useState } from "react";
import PropTypes from "prop-types";

import { COVERS, CATEGORIES, OPERATIONS } from "../../constants";
import { StyledWhitePage, StyledRecord } from "./styles";

const EditForm = ({
  record,
  handleSave,
  handleDelete,
  getStorageRef,
  operation,
}) => {
  const [artistValue, setArtistValue] = useState(record.artist);
  const [aSideValue, setASideValue] = useState(record.a_side);
  const [bSideValue, setBSideValue] = useState(record.b_side);
  const [coverIdValue, setCoverIdValue] = useState(
    parseInt(record.cover_id, 10),
  );
  const [catIdValue, setCatIdValue] = useState(
    parseInt(record.cat_id, 10),
  );
  const [imgUrlValue, setImgUrlValue] = useState(record.imgUrl || "");
  const [imgPreview, setImgPreview] = useState(false);
  const [youtubeId1Value, setYoutubeId1Value] = useState(
    record.youtube_id1 || "",
  );
  const [youtubeId2Value, setYoutubeId2Value] = useState(
    record.youtube_id2 || "",
  );
  const [loading, setLoading] = useState(false);
  const [imgFileName, setImgFileName] = useState("");
  const [imgFileExtension, setImgFileExtension] = useState("jpg");

  const saveRecord = (e) => {
    e && e.preventDefault();
    const updatedRecord = { ...record };
    // loop over form fields
    // @TODO one issue with this approach is that it doesn't account for types
    Object.values(e.target.elements).forEach((element) => {
      if (element.id) updatedRecord[element.id] = element.value;
    });

    if (imgFileName.length > 0) {
      const resizedFileName = `/covers/500/${record.id}_500x500.${imgFileExtension}`;
      const fileRef = getStorageRef().child(resizedFileName);
      fileRef
        .getDownloadURL()
        .then((result) => {
          updatedRecord.imgUrl = result;
        })
        .then(() => {
          console.log("saving (with new image):", updatedRecord);
          return handleSave(updatedRecord, operation);
        });
    } else {
      return handleSave(updatedRecord, operation);
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (Math.abs(this.width - this.height) > 10) {
          alert(
            "Deze foto is niet vierkant. Probeer opnieuw. Tip: je telefoon heeft een instelling waarmee je vierkante foto's kunt maken.",
          );
        } else {
          setImgPreview(e.target.result);
          uploadCover(file);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const uploadCover = (file) => {
    const ext = file.name.split(".").pop();
    // save ext for later use (upon save)
    setImgFileExtension(ext);
    const fileName = `/covers/500/${record.id}.${ext}`;
    setImgFileName(fileName);
    const fileRef = getStorageRef().child(fileName);

    setLoading(true);
    fileRef.put(file).then(function (result) {
      //Get URL and store to pass
      fileRef.getDownloadURL().then((result) => {
        setImgUrlValue(result);
        setLoading(false);
      });
    });
  };

  // const onUrlChange = (e) => {
  //   // var remoteimageurl =
  //   //   "https://images-na.ssl-images-amazon.com/images/I/81dUVKQDBEL._SL1200_.jpg";
  //   const remoteimageurl = e.target.value;

  //   fetch(remoteimageurl)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((blob) => {
  //       if (blob.size < 10000) {
  //         alert(
  //           "Deze foto kan niet worden gedownload. Probeer opnieuw te plakken, soms werkt dat. Of probeer een andere URL.",
  //         );
  //         return;
  //       }
  //       //uploading blob to firebase storage
  //       const fileRef = getStorageRef().child(
  //         `/covers/500/${record.id}.jpg`,
  //       );
  //       fileRef
  //         .put(blob)
  //         .then((snapshot) => snapshot.ref.getDownloadURL())
  //         .then((fileUrl) => {
  //           console.log(fileUrl);
  //           setImgUrlValue(fileUrl);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <StyledWhitePage>
      <h1>
        {artistValue && aSideValue && bSideValue
          ? `${artistValue} - ${aSideValue} / ${bSideValue}`
          : "Single toevoegen"}
      </h1>
      <StyledRecord>
        <div>
          {coverIdValue === COVERS.PHOTO.ID ? (
            <img src={imgUrlValue} alt={`Cover ${aSideValue}`} />
          ) : (
            <img src={`/img/cover_${coverIdValue}.png`} alt="Cover" />
          )}
        </div>
        <div>
          <form onSubmit={saveRecord}>
            <ul>
              <li>
                <label htmlFor="artist">Artiest:</label>
                <input
                  id="artist"
                  value={artistValue}
                  onChange={(e) => setArtistValue(e.target.value)}
                  required
                />
              </li>
              <li>
                <label htmlFor="a_side">A Kant:</label>
                <input
                  id="a_side"
                  value={aSideValue}
                  onChange={(e) => setASideValue(e.target.value)}
                  required
                />
              </li>
              <li>
                <label htmlFor="b_side">B Kant:</label>
                <input
                  id="b_side"
                  value={bSideValue}
                  onChange={(e) => setBSideValue(e.target.value)}
                  required
                />
              </li>
              <li>
                <label htmlFor="cover_id">Type hoes:</label>
                <select
                  id="cover_id"
                  value={coverIdValue}
                  onChange={(e) =>
                    setCoverIdValue(parseInt(e.target.value, 10))
                  }
                >
                  {Object.values(COVERS).map((cover) => (
                    <option value={cover.ID} key={cover.ID}>
                      {cover.LABEL_NL}
                    </option>
                  ))}
                </select>
              </li>
              {coverIdValue === COVERS.PHOTO.ID && (
                <li>
                  <label htmlFor="cover_file">Upload hoes:</label>
                  <div className="fieldset">
                    <input
                      type="file"
                      id="cover_file"
                      accept="image/*"
                      onChange={onFileChange}
                      disabled={loading}
                    />
                    {/* <div>of</div>
                    <input
                      onPaste={onUrlChange}
                      placeholder="URL van hoes plakken"
                    /> */}
                    <div>
                      <small>
                        {loading ? (
                          <em>Effe wachten...</em>
                        ) : (
                          <a
                            href={`https://www.google.com/search?as_st=y&tbm=isch&hl=en&as_q=${encodeURI(
                              artistValue,
                            )}%20${encodeURI(
                              aSideValue,
                            )}%20${encodeURI(
                              bSideValue,
                            )}+cover&as_epq=&as_oq=&as_eq=&cr=&as_sitesearch=&safe=images&tbs=isz:lt,islt:qsvga,iar:s`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Google hoezen!
                          </a>
                        )}
                      </small>
                    </div>
                  </div>
                </li>
              )}
              <li>
                <label htmlFor="cat_id">Categorie:</label>
                <select
                  id="cat_id"
                  value={catIdValue}
                  onChange={(e) =>
                    setCatIdValue(parseInt(e.target.value, 10))
                  }
                >
                  {Object.values(CATEGORIES).map((categorie) => {
                    if (categorie.ID !== CATEGORIES.DELETED.ID) {
                      return (
                        <option
                          value={categorie.ID}
                          key={categorie.ID}
                        >
                          {categorie.LABEL_NL}
                        </option>
                      );
                    }
                  })}
                </select>
              </li>
              <li>
                <label htmlFor="youtube_id1">
                  YouTube ID A Kant:
                </label>
                <div className="fieldset">
                  <input
                    id="youtube_id1"
                    value={youtubeId1Value}
                    onChange={(e) =>
                      setYoutubeId1Value(e.target.value.trim())
                    }
                  />
                  <div>
                    <small>
                      <a
                        href={`https://www.youtube.com/results?search_query=${encodeURI(
                          artistValue,
                        )}%20${encodeURI(aSideValue)}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Zoeken op YouTube
                      </a>
                    </small>
                  </div>
                </div>
              </li>
              <li>
                <label htmlFor="youtube_id2">
                  YouTube ID B Kant:
                </label>
                <div className="fieldset">
                  <input
                    id="youtube_id2"
                    value={youtubeId2Value}
                    onChange={(e) =>
                      setYoutubeId2Value(e.target.value.trim())
                    }
                  />
                  <div>
                    <small>
                      <a
                        href={`https://www.youtube.com/results?search_query=${encodeURI(
                          artistValue,
                        )}%20${encodeURI(bSideValue)}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Zoeken op YouTube
                      </a>
                    </small>
                  </div>
                </div>
              </li>
            </ul>
            <button type="submit">
              {operation === OPERATIONS.CREATE
                ? "Toevoegen"
                : "Opslaan"}
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                history.back();
              }}
            >
              Annuleren
            </button>
            {operation !== OPERATIONS.CREATE && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                Single verwijderen?
              </button>
            )}
          </form>
        </div>
      </StyledRecord>
    </StyledWhitePage>
  );
};

EditForm.propTypes = {
  record: PropTypes.shape({
    artist: PropTypes.string,
    a_side: PropTypes.string,
    b_side: PropTypes.string,
  }),
  handleSave: PropTypes.func.isRequired,
  operation: PropTypes.string,
};

export default EditForm;
